import { Injectable } from '@angular/core';
import { Search } from '../core/models/search';
import { HttpClient } from '@angular/common/http';
import { map, filter, flatMap, concatMap } from 'rxjs/operators';
import { TransactionResponse } from '../transactions/models/transaction';

@Injectable({
    providedIn: 'root'
})
export class PatientsService {

    constructor(private http: HttpClient) { }

    searchPatientTransactions(search: Search) {
        search.pagination = { pageIndex: 1, itemsPerPage: 999 };
        const isToday = (someDateString) => {
            const someDate = new Date(someDateString);
            const today = new Date();
            return someDate.getDate() === today.getDate() &&
                someDate.getMonth() === today.getMonth() &&
                someDate.getFullYear() === today.getFullYear();
        };
        return this.http.post<any>('transaction/transactions/', search).pipe(
            map(transactions => transactions.records.filter(x => isToday(x.transactionDate)))
        );
    }

    searchPatientInfo(accountNumber: string) {
        return this.http.post<any>('member/memberinfo/', { accountNumber }).pipe(
            map(info => info.record)
        );
    }

    confirmLoa(referenceNumber, pinCode) {
        const body = {
            referenceNumber,
            pinCode
        };
        return this.http.post('transaction/confirmloa/', body);
    }

    cancelLoa(referenceNumber: any) {
        const body = {
            referenceNumber,
        };
        return this.http.post('transaction/CancelLoa/', body);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Search } from 'src/app/core/models/search';
import { Contract } from '../models/policy';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  constructor(private http: HttpClient) {}

  public searchContracts(searchCriteria: Search) {
    return this.http.post<any>(`hmo/contracts`, searchCriteria).pipe(
      map(response => {
        return response;
      })
    );
  }

  public deleteContract(contractNumber: string) {
    const body = {
      contractNumber
    };
    return this.http.post<any>(`hmo/deletecontract`, body);
  }

  public getContractInfo(contractNumber: string): Observable<Contract> {
    const body = {
      contractNumber
    };
    return this.http.post<any>(`hmo/contractinfo`, body).pipe(
      map(result => {
        if (result.record) {
          return result.record;
        }
      })
    );
  }

  public saveContractInfo(conttractDetails: Contract) {
    const body = {
      contractNumber: conttractDetails.contractNumber,
      contractInfo: conttractDetails.contractInfo
    };
    return this.http.post<any>(`hmo/savecontract`, body).pipe(
      map(result => {
        return result;
      })
    );
  }

  // TODO: Use the proper get hmoproviderinfo. This is for temporary call only
  public getHMOProviderInfo(hmoProviderId: string) {
    return this.http
      .post<any>(`hmo/HmoProviderInfo`, { hmoProviderId })
      .pipe(
        map(result => {
          if (result.record) {
            return result.record;
          }
        })
      );
  }
}

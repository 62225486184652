import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, retry, catchError } from 'rxjs/operators';
import { ProductPackageInfo } from '../models/product-packages.model';
import { throwError } from 'rxjs';
import { Search } from 'src/app/core/models/search';

@Injectable({
  providedIn: 'root'
})
export class ProductPackageManagementService {

  constructor(private http: HttpClient) { }

  public searchProductPackages(searchCriteria: Search) {
    return this.http.post<any>(`hmo/productpackages`, searchCriteria)
      .pipe(map(result => {
        if (result) {
          return result;
        }
      }));
  }

  // TODO: This service must be from the product management instead from here
  public searchProduct() {
    const body = {
      searchCriteria: [
        { productId: '' }, { serviceGroupCode: '' }, { product: '' }, { startCode: '' }, { endCode: '' }
      ],
      sorting: {
        column: 'product',
        order: 'asc',
      },
      pagination: {
        pageIndex: 1,
        itemsPerPage: 9999
      }
    };
    return this.http.post<any>(`hmo/products`, body)
      .pipe(map(result => {
        if (result.records) {
          return result.records;
        }
      }));
  }

  public getProductPackageInfo(productPackageId: string) {
    const body = {
      productPackageId
    };
    return this.http.post<any>(`hmo/productpackageinfo`, body)
      .pipe(map(result => {
        if (result.record) {
          return result.record;
        }
      }));
  }

  public saveProductPackageInfo(productPackageInfo: ProductPackageInfo) {
    return this.http.post<any>(`hmo/saveproductpackage`, productPackageInfo)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }

  public deleteProductPackage(productPackageId: string) {
    const body = {
      productPackageId
    };

    return this.http.post<any>(`hmo/deleteproductpackage`, body)
      .pipe(map(result => {
        return result;
      }));
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
